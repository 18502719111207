import React from 'react'
import theme from '../../../../gatsby-plugin-theme-ui/index.js'
import { graphql, useStaticQuery } from 'gatsby'

export const query = graphql`
  query {
    site {
      siteMetadata {
        hero {
          heading
        }
      }
    }
  }
`

export default function Logo() {
  const {
    site: {
      siteMetadata: { hero }
    }
  } = useStaticQuery(query)

  return (
    <>
      <h1 style={{ color: theme.colors.accent }}>{hero.heading}</h1>
    </>
  )
}
